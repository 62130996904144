import { Suspense, lazy, useEffect } from 'react';
import { Router, Switch, Route, useHistory } from 'react-router-dom';
import { History } from 'history';
import { Loader, OVLocalCache } from 'outvio-ui';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import OutvioMuiTheme from '../config/material-ui-theme';
import PageLoadErrorBoundary from './components/PageLoadErrorBoundary';

import 'cms/styles/index.css';

const SentryRoute = Sentry.withSentryRouting(Route as any);

const AuthModule = lazy(() => import('./modules/AuthModule/module'));
const CmsModule = lazy(() => import('./modules/CmsModule/module'));
const AdminModule = lazy(() => import('./modules/AdminModule/module'));

const theme = createTheme(OutvioMuiTheme);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // These are disabled for now, to not increase load on API for now
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// Temp solution for the initial auto-login
const RootRedirect = () => {
  const history = useHistory();
  useEffect(() => {
    OVLocalCache.get('token')
      .then((token) => {
        if (token) {
          history.push(`/cms/dashboard${window.location.search}`);
        } else {
          history.push(`/auth/login${window.location.search}`);
        }
      })
      .catch(() => history.push(`/auth/login${window.location.search}`));
  }, []);

  return <Loader />;
};

interface IProps {
  customHistory: History;
}

const Routes = ({ customHistory }: IProps) => (
  <MuiThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <Router history={customHistory}>
        <PageLoadErrorBoundary>
          <Suspense fallback={<Loader />}>
            <Switch>
              <SentryRoute path="/" exact component={RootRedirect} />
              <SentryRoute path="/auth" component={AuthModule} />
              <SentryRoute path="/cms" component={CmsModule} />
              <SentryRoute path="/admin" component={AdminModule} />
            </Switch>
          </Suspense>
        </PageLoadErrorBoundary>
      </Router>
    </QueryClientProvider>
  </MuiThemeProvider>
);

export default Routes;

export default {
  palette: {
    primary: {
      main: '#4bb4b3',
    },
    action: {
      disabled: '#c6dbdd',
    },
    text: {
      primary: '#173c40',
      secondary: '#c6dbdd',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "'Open Sans', sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1100,
      md: 1200,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiSwitch: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'rgba(75, 180, 179, 0.15)',
        },
        '&$checked': {
          color: '#4bb4b3',
          '&:hover': {
            backgroundColor: 'rgba(75, 180, 179, 0.15)',
          },
        },
        '&$checked + $track': {
          backgroundColor: '#4bb4b3',
        },
      },
    },
    MuiRadio: {
      root: {
        color: '#6a959a',
        fontSize: '22px',
        '&:hover': {
          backgroundColor: '#dbf0f0',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: '#4bb4b3',
          '&:hover': {
            backgroundColor: '#dbf0f0',
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#6a959a',
        fontSize: '22px',
        '&:hover': {
          backgroundColor: '#dbf0f0',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: '#4bb4b3',
          '&:hover': {
            backgroundColor: '#dbf0f0',
          },
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: '8px',
      },
      label: {
        fontSize: '13px',
        lineHeight: '1.38',
      },
    },
    MuiTab: {
      textColorPrimary: {
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#4bb4b3',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgb(0, 54, 61, 0.75)',
        color: '#fff',
        fontSize: '11px',
        fontFamily: 'Open Sans',
        boxShadow: '0px 0px 5px 0px #C6DBDD',
        fontWeight: 500,
      },
      tooltipPlacementLeft: {
        margin: '0 12px !important',
      },
      tooltipPlacementRight: {
        margin: '0 -12px !important',
      },
      tooltipPlacementTop: {
        margin: '6px 0 !important',
      },
      tooltipPlacementBottom: {
        margin: '6px 0 !important',
      },
    },
  },
};

import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import Routes from './routes';
import PackageJson from '../package.json';

console.log(`version: ${PackageJson.version}`);

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://927bddc1874542e0978c1f786e9e985c@sentry.io/1320195',
  release: PackageJson.version,
  environment: process.env.OUTVIO_APP_ENV,
  enabled: process.env.NODE_ENV === 'production' && process.env.OUTVIO_APP_ENV === 'production',
  // Ignore errors from local development
  denyUrls: ['app.outvio.dkr'],
  // Ignore common but handled errors
  ignoreErrors: [
    'ChunkLoadError',
    'Loading chunk',
    'Non-Error promise rejection captured with keys',
    'Failed to fetch',
    "Unexpected token '<'",
  ],
  // APM
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.15,
});

if (process.env.OUTVIO_APP_ENV === 'production') {
  // Insert Google tag manager
  const scriptGTMHead = document.createElement('script');
  scriptGTMHead.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
  {'gtm.start': new Date().getTime(),event:'gtm.js'}
  );var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5KR7XJ5');`;
  document.head.appendChild(scriptGTMHead);
  // Insert Active Campaign Site Tracking
  const scriptAC = document.createElement('script');
  scriptAC.text = `function acEnableTracking() {
    var expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30);
    document.cookie = 'ac_enable_tracking=1; expires= ' + expiration + '; path=/';
    acTrackVisit();
  }
  function acTrackVisit() {
    var trackcmp_email = '';
    var trackcmp = document.createElement('script');
    trackcmp.async = true;
    trackcmp.type = 'text/javascript';
    trackcmp.src =
      '//trackcmp.net/visit?actid=610056526&e=' +
      encodeURIComponent(trackcmp_email) +
      '&r=' +
      encodeURIComponent(document.referrer) +
      '&u=' +
      encodeURIComponent(window.location.href);
    var trackcmp_s = document.getElementsByTagName('script');
    if (trackcmp_s.length) {
      trackcmp_s[0].parentNode.appendChild(trackcmp);
    } else {
      var trackcmp_h = document.getElementsByTagName('head');
      trackcmp_h.length && trackcmp_h[0].appendChild(trackcmp);
    }
  }
  if (/(^|; )ac_enable_tracking=([^;]+)/.test(document.cookie)) {
    acEnableTracking();
  }`;
  document.head.appendChild(scriptAC);
}

render(<Routes customHistory={history} />, document.getElementById('root'));
